import React from 'react';

const IndividualRecipesPlaceholder = () => {

  return (
    <div></div>
  )
}

export default IndividualRecipesPlaceholder;
