const Countries = [
  ["1", ""],
  ["2", "Afghanistan"],
  ["3", "Albania"],
  ["4", "Algeria"],
  ["5", "Angola"],
  ["6", "Argentina"],
  ["7", "Armenia"],
  ["8", "Australia"],
  ["9", "Austria"],
  ["10", "Azerbaijan"],
  ["11", "Bahrain"],
  ["12", "Bangladesh"],
  ["13", "Belarus"],
  ["14", "Belgium"],
  ["15", "Belize"],
  ["16", "Benin"],
  ["17", "Bhutan"],
  ["18", "Bolivia"],
  ["19", "Bosnia and Herzegovina"],
  ["20", "Botswana"],
  ["21", "Brazil"],
  ["22", "Brunei"],
  ["23", "Bulgaria"],
  ["24", "Burkina Faso"],
  ["25", "Burundi"],
  ["26", "Cote d'Ivoire"],
  ["27", "Cambodia"],
  ["28", "Cameroon"],
  ["29", "Canada"],
  ["30", "Central African Republic"],
  ["31", "Chad"],
  ["32", "Chile"],
  ["33", "China"],
  ["34", "Colombia"],
  ["35", "Congo"],
  ["36", "Costa Rica"],
  ["37", "Croatia"],
  ["38", "Cuba"],
  ["39", "Czech Republican"],
  ["40", "Denmark"],
  ["41", "Dominican Republican"],
  ["42", "Ecuador"],
  ["43", "Egypt"],
  ["44", "El Salvador"],
  ["45", "Estonia"],
  ["46", "Ethiopia"],
  ["47", "Finland"],
  ["48", "France"],
  ["49", "Gabon"],
  ["50", "Gambia"],
  ["51", "Georgia"],
  ["52", "Germany"],
  ["53", "Ghana"],
  ["54", "Greece"],
  ["55", "Guatemala"],
  ["56", "Guinea"],
  ["57", "Honduras"],
  ["58", "Hungary"],
  ["59", "Iceland"],
  ["60", "India"],
  ["61", "Indonesia"],
  ["62", "Iran"],
  ["63", "Iraq"],
  ["64", "Ireland"],
  ["65", "Israel"],
  ["66", "Italy"],
  ["67", "Jamaica"],
  ["68", "Japan"],
  ["69", "Jordan"],
  ["70", "Kazakhstan"],
  ["71", "Kenya"],
  ["72", "Korea"],
  ["73", "Kuwait"],
  ["74", "Kyrgystan"],
  ["75", "Laos"],
  ["76", "Latvia"],
  ["77", "Lebanon"],
  ["78", "Liberia"],
  ["79", "Libya"],
  ["80", "Lithuania"],
  ["81", "Madagascar"],
  ["82", "Malawi"],
  ["83", "Malaysia"],
  ["84", "Mali"],
  ["85", "Mexico"],
  ["86", "Moldova"],
  ["87", "Mongolia"],
  ["88", "Morocco"],
  ["89", "Mozambique"],
  ["90", "Myanmar"],
  ["91", "Namibia"],
  ["92", "Nepal"],
  ["93", "Netherlands"],
  ["94", "New Zealand"],
  ["95", "Nicaragua"],
  ["96", "Niger"],
  ["97", "Nigeria"],
  ["98", "Norway"],
  ["99", "Oman"],
  ["100", "Pakistan"],
  ["101", "Palestine"],
  ["102", "Panama"],
  ["103", "Paraguay"],
  ["104", "Peru"],
  ["105", "Philippines"],
  ["106", "Poland"],
  ["107", "Portugal"],
  ["108", "Qatar"],
  ["109", "Romania"],
  ["110", "Russia"],
  ["111", "Rwanda"],
  ["112", "Saudi Arabia"],
  ["113", "Senegal"],
  ["114", "Serbia"],
  ["115", "Sierra Leone"],
  ["116", "Singapore"],
  ["117", "Slovakia"],
  ["118", "Slovenia"],
  ["119", "Somalia"],
  ["120", "South Africa"],
  ["121", "Spain"],
  ["122", "Sri Lanka"],
  ["123", "Sudan"],
  ["124", "Sweden"],
  ["125", "Switzerland"],
  ["126", "Syria"],
  ["127", "Tajikistan"],
  ["128", "Tanzania"],
  ["129", "Thailand"],
  ["130", "Togo"],
  ["131", "Trinidad and Tobago"],
  ["132", "Tunisia"],
  ["133", "Turkey"],
  ["134", "Turkmenistan"],
  ["135", "Uganda"],
  ["136", "United Arab Emirates"],
  ["137", "United Kingdom"],
  ["138", "United States of America"],
  ["139", "Uruguay"],
  ["140", "Uzbekistan"],
  ["141", "Venezuela"],
  ["142", "Vietnam"],
  ["143", "Yemen"],
  ["144", "Zambia"],
  ["145", "Zimbabwe"],
]

export default Countries;
