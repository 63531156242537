import React from 'react';
import { Pagination } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const AdvancedSearchPaginate = ({
  pages,
  page,
  keywordRecipeName = '',
  keywordCountry = '',
  keywordChefName = '',
  keywordCookTimeMin = '',
  keywordCookTimeMax = '',
  keywordIsVegan = '',
  keywordIsVegetarian = '',
  keywordIsGlutenFree = '',
  keywordIsKetogenic = '',
  keywordIsPescatarian = '',
  keywordIsDairy = '',
  keywordIsEgg = '',
  keywordIsNuts = '',
  keywordIsShellfish = '',
  keywordIsSoy = '',
  keywordIsWheat = '',
  keywordIsBreakfastBrunch = '',
  keywordIsMainDish = '',
  keywordIsSideSauce = '',
  keywordIsDessert = '',
  keywordIsSnack = '',
  keywordIsAppetizer = '',
  keywordIsDrink = ''
}) => {
  return pages > 1 && (
    <div>
      <Pagination className='d-flex justify-content-center'>
        {[...Array(pages).keys()].map(x => (
          <LinkContainer
            key={x + 1}
            to={
                (keywordRecipeName !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/page/${x+1}`
              : (keywordCountry !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordCountry=${keywordCountry}/page/${x+1}`
              : (keywordChefName !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordChefName=${keywordChefName}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordCountry !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordCountry=${keywordCountry}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordChefName !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordChefName=${keywordChefName}/page/${x+1}`
              : (keywordCountry !== '' && keywordChefName !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordCountry=${keywordCountry}/keywordChefName=${keywordChefName}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordCountry !== '' && keywordChefName !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordCountry=${keywordCountry}/keywordChefName=${keywordChefName}/page/${x+1}`

              : (keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordCountry !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordCountry=${keywordCountry}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordChefName !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordChefName=${keywordChefName}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordCountry !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordCountry=${keywordCountry}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordChefName !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordChefName=${keywordChefName}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordCountry !== '' && keywordChefName !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordCountry=${keywordCountry}/keywordChefName=${keywordChefName}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordCountry !== ''  && keywordChefName !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordCountry=${keywordCountry}/keywordChefName=${keywordChefName}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`

              : (keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/page/${x+1}`
              : (keywordCountry !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordCountry=${keywordCountry}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/page/${x+1}`
              : (keywordChefName !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordChefName=${keywordChefName}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordCountry !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordCountry=${keywordCountry}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordChefName !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordChefName=${keywordChefName}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/page/${x+1}`
              : (keywordCountry !== '' && keywordChefName !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordCountry=${keywordCountry}/keywordChefName=${keywordChefName}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordCountry !== ''  && keywordChefName !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordCountry=${keywordCountry}/keywordChefName=${keywordChefName}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/page/${x+1}`

              : (keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordCountry !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordCountry=${keywordCountry}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordChefName !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordChefName=${keywordChefName}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordCountry !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordCountry=${keywordCountry}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordChefName !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordChefName=${keywordChefName}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordCountry !== '' && keywordChefName !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordCountry=${keywordCountry}/keywordChefName=${keywordChefName}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordCountry !== ''  && keywordChefName !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordCountry=${keywordCountry}/keywordChefName=${keywordChefName}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`

              : (keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordCountry !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordCountry=${keywordCountry}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordChefName !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordChefName=${keywordChefName}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordCountry !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordCountry=${keywordCountry}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordChefName !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordChefName=${keywordChefName}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordCountry !== '' && keywordChefName !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordCountry=${keywordCountry}/keywordChefName=${keywordChefName}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordCountry !== ''  && keywordChefName !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordCountry=${keywordCountry}/keywordChefName=${keywordChefName}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`

              : (keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/page/${x+1}`
              : (keywordCountry !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordCountry=${keywordCountry}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/page/${x+1}`
              : (keywordChefName !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordChefName=${keywordChefName}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordCountry !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordCountry=${keywordCountry}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordChefName !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordChefName=${keywordChefName}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/page/${x+1}`
              : (keywordCountry !== '' && keywordChefName !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordCountry=${keywordCountry}/keywordChefName=${keywordChefName}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordCountry !== ''  && keywordChefName !== '' && keywordIsVegan !== '' && keywordIsVegetarian !== '' && keywordIsGlutenFree !== '' && keywordIsKetogenic !== '' && keywordIsPescatarian !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordCountry=${keywordCountry}/keywordChefName=${keywordChefName}/keywordIsVegan=${keywordIsVegan}/keywordIsVegetarian=${keywordIsVegetarian}/keywordIsGlutenFree=${keywordIsGlutenFree}/keywordIsKetogenic=${keywordIsKetogenic}/keywordIsPescatarian=${keywordIsPescatarian}/page/${x+1}`

              : (keywordIsVegan === 'true' && keywordIsVegetarian === '' && keywordIsGlutenFree === '' && keywordIsKetogenic === '' && keywordIsPescatarian === '') ? `/recipes/advanced-search-results/keywordIsVegan=${keywordIsVegan}/page/${x+1}`
              : (keywordIsVegan === '' && keywordIsVegetarian === 'true' && keywordIsGlutenFree === '' && keywordIsKetogenic === '' && keywordIsPescatarian === '') ? `/recipes/advanced-search-results/keywordIsVegetarian=${keywordIsVegetarian}/page/${x+1}`
              : (keywordIsVegan === '' && keywordIsVegetarian === '' && keywordIsGlutenFree === 'true' && keywordIsKetogenic === '' && keywordIsPescatarian === '') ? `/recipes/advanced-search-results/keywordIsGlutenFree=${keywordIsGlutenFree}/page/${x+1}`
              : (keywordIsVegan === '' && keywordIsVegetarian === '' && keywordIsGlutenFree === '' && keywordIsKetogenic === 'true' && keywordIsPescatarian === '') ? `/recipes/advanced-search-results/keywordIsKetogenic=${keywordIsKetogenic}/page/${x+1}`
              : (keywordIsVegan === '' && keywordIsVegetarian === '' && keywordIsGlutenFree === '' && keywordIsKetogenic === '' && keywordIsPescatarian === 'true') ? `/recipes/advanced-search-results/keywordIsPescatarian=${keywordIsPescatarian}/page/${x+1}`

              : (keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/page/${x+1}`
              : (keywordCountry !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordCountry=${keywordCountry}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/page/${x+1}`
              : (keywordChefName !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordChefName=${keywordChefName}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordCountry !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordCountry=${keywordCountry}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordChefName !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordChefName=${keywordChefName}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/page/${x+1}`
              : (keywordCountry !== '' && keywordChefName !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordCountry=${keywordCountry}/keywordChefName=${keywordChefName}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordCountry !== ''  && keywordChefName !== '' && keywordIsDairy !== '' && keywordIsEgg !== '' && keywordIsNuts !== '' && keywordIsShellfish !== '' && keywordIsSoy !== '' && keywordIsWheat !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordCountry=${keywordCountry}/keywordChefName=${keywordChefName}/keywordIsDairy=${keywordIsDairy}/keywordIsEgg=${keywordIsEgg}/keywordIsNuts=${keywordIsNuts}/keywordIsShellfish=${keywordIsShellfish}/keywordIsSoy=${keywordIsSoy}/keywordIsWheat=${keywordIsWheat}/page/${x+1}`

              : (keywordIsDairy === 'true' && keywordIsEgg === '' && keywordIsNuts === '' && keywordIsShellfish === '' && keywordIsSoy === '' && keywordIsWheat === '') ? `/recipes/advanced-search-results/keywordIsDairy=${keywordIsDairy}/page/${x+1}`
              : (keywordIsDairy === '' && keywordIsEgg === 'true' && keywordIsNuts === '' && keywordIsShellfish === '' && keywordIsSoy === '' && keywordIsWheat === '') ? `/recipes/advanced-search-results/keywordIsEgg=${keywordIsEgg}/page/${x+1}`
              : (keywordIsDairy === '' && keywordIsEgg === '' && keywordIsNuts === 'true' && keywordIsShellfish === '' && keywordIsSoy === '' && keywordIsWheat === '') ? `/recipes/advanced-search-results/keywordIsNuts=${keywordIsNuts}/page/${x+1}`
              : (keywordIsDairy === '' && keywordIsEgg === '' && keywordIsNuts === '' && keywordIsShellfish === 'true' && keywordIsSoy === '' && keywordIsWheat === '') ? `/recipes/advanced-search-results/keywordIsShellfish=${keywordIsShellfish}/page/${x+1}`
              : (keywordIsDairy === '' && keywordIsEgg === '' && keywordIsNuts === '' && keywordIsShellfish === '' && keywordIsSoy === 'true' && keywordIsWheat === '') ? `/recipes/advanced-search-results/keywordIsSoy=${keywordIsSoy}/page/${x+1}`
              : (keywordIsDairy === '' && keywordIsEgg === '' && keywordIsNuts === '' && keywordIsShellfish === '' && keywordIsSoy === '' && keywordIsWheat === 'true') ? `/recipes/advanced-search-results/keywordIsWheat=${keywordIsWheat}/page/${x+1}`

              : (keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordCountry !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordCountry=${keywordCountry}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordChefName !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordChefName=${keywordChefName}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordCountry !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordCountry=${keywordCountry}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordChefName !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordChefName=${keywordChefName}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordCountry !== '' && keywordChefName !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordCountry=${keywordCountry}/keywordChefName=${keywordChefName}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`
              : (keywordRecipeName !== '' && keywordCountry !== ''  && keywordChefName !== '' && keywordIsBreakfastBrunch !== '' && keywordIsMainDish !== '' && keywordIsSideSauce !== '' && keywordIsDessert !== '' && keywordIsSnack !== '' && keywordIsAppetizer !== '' && keywordIsDrink !== '') ? `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/keywordRecipeName=${keywordRecipeName}/keywordCountry=${keywordCountry}/keywordChefName=${keywordChefName}/keywordIsBreakfastBrunch=${keywordIsBreakfastBrunch}/keywordIsMainDish=${keywordIsMainDish}/keywordIsSideSauce=${keywordIsSideSauce}/keywordIsDessert=${keywordIsDessert}/keywordIsSnack=${keywordIsSnack}/keywordIsAppetizer=${keywordIsAppetizer}/keywordIsDrink=${keywordIsDrink}/page/${x+1}`

              : `/recipes/advanced-search-results/keywordCookTimeMin=${keywordCookTimeMin}/keywordCookTimeMax=${keywordCookTimeMax}/page/${x+1}`
            }
          >
            <Pagination.Item active={x+1 === page}>{x+1}</Pagination.Item>
          </LinkContainer>
        ))}
      </Pagination>
    </div>
  )
}

export default AdvancedSearchPaginate;
