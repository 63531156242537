const Measurements = [
  ["1", ""],
  ["2", "Teaspoon"],
  ["3", "Tablespoon"],
  ["4", "Fluid Ounce"],
  ["5", "Cup"],
  ["6", "Pint"],
  ["7", "Quart"],
  ["8", "Gallon"],
  ["9", "Ounce"],
  ["10", "Pound"],
  ["11", "Inch"],
  ["12", "Can (Ounce)"],
  ["13", "Box"],
  ["14", "Jar"],
  ["15", "Bag"],
  ["16", "Pinch"],
  ["17", "Millilitre"],
  ["18", "Litre"],
  ["19", "Gram"],
  ["20", "Kilogram"],
  ["21", "Centimetre"],
  ["22", "Millimetre"],
]

export default Measurements;
