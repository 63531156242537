export const COOKBOOK_CREATE_REQUEST = 'COOKBOOK_CREATE_REQUEST'
export const COOKBOOK_CREATE_SUCCESS = 'COOKBOOK_CREATE_SUCCESS'
export const COOKBOOK_CREATE_FAILURE = 'COOKBOOK_CREATE_FAILURE'
export const COOKBOOK_CREATE_RESET= 'COOKBOOK_CREATE_RESET'

export const COOKBOOK_MYLIST_REQUEST = 'COOKBOOK_MYLIST_REQUEST'
export const COOKBOOK_MYLIST_SUCCESS = 'COOKBOOK_MYLIST_SUCCESS'
export const COOKBOOK_MYLIST_FAILURE = 'COOKBOOK_MYLIST_FAILURE'

export const COOKBOOK_DETAILS_REQUEST = 'COOKBOOK_DETAILS_REQUEST'
export const COOKBOOK_DETAILS_SUCCESS = 'COOKBOOK_DETAILS_SUCCESS'
export const COOKBOOK_DETAILS_FAILURE = 'COOKBOOK_DETAILS_FAILURE'
export const COOKBOOK_DETAILS_RESET = 'COOKBOOK_DETAILS_RESET'

export const COOKBOOK_UPDATE_REQUEST = 'COOKBOOK_UPDATE_REQUEST'
export const COOKBOOK_UPDATE_SUCCESS = 'COOKBOOK_UPDATE_SUCCESS'
export const COOKBOOK_UPDATE_FAILURE = 'COOKBOOK_UPDATE_FAILURE'
export const COOKBOOK_UPDATE_RESET= 'COOKBOOK_UPDATE_RESET'

export const COOKBOOK_DELETE_REQUEST = 'COOKBOOK_DELETE_REQUEST'
export const COOKBOOK_DELETE_SUCCESS = 'COOKBOOK_DELETE_SUCCESS'
export const COOKBOOK_DELETE_FAILURE = 'COOKBOOK_DELETE_FAILURE'
