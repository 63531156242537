// Can remove lazy loading, but eventually should test will all the routes first, should enhance performace
import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Header from './components/Header/Header.component';
import IconBar from './components/IconBar/IconBar.component';
import Footer from './components/Footer/Footer.component';

//import HomePage from './pages/HomePage/HomePage.component';
import LoginPage from './pages/LoginPage/LoginPage.component';
import RegisterPage from './pages/RegisterPage/RegisterPage.component';
import ProfileEditPage from './pages/ProfileEditPage/ProfileEditPage.component';
import ChefRecipesListPage from './pages/ChefRecipesListPage/ChefRecipesListPage.component';
import ChefRecipeEditPage from './pages/ChefRecipeEditPage/ChefRecipeEditPage.component';
import ChefSavedRecipesListPage from './pages/ChefSavedRecipesListPage/ChefSavedRecipesListPage.component';
import ChefSavedRecipesDeletePage from './pages/ChefSavedRecipesDeletePage/ChefSavedRecipesDeletePage.component';
import AllRecipesPage from './pages/AllRecipesPage/AllRecipesPage.component';
import AdvancedRecipeSearchPage from './pages/AdvancedRecipeSearchPage/AdvancedRecipeSearchPage.component';
import AdvancedRecipeSearchResultsPage from './pages/AdvancedRecipeSearchResultsPage/AdvancedRecipeSearchResultsPage.component';
import IndividualRecipesPlaceholder from './pages/IndividualRecipesPlaceholder/IndividualRecipesPlaceholder.component';
import IndividualRecipePage from './pages/IndividualRecipePage/IndividualRecipePage.component';
import SavedIngredientsPage from './pages/SavedIngredientsPage/SavedIngredientsPage.component';
import WorldMapPage from './pages/WorldMapPage/WorldMapPage.component';
import AllChefsPage from './pages/AllChefsPage/AllChefsPage.component';
import IndividualChefPage from './pages/IndividualChefPage/IndividualChefPage.component';
import ChefMyFollowedPage from './pages/ChefMyFollowedPage/ChefMyFollowedPage.component';
import MyFoodsPage from './pages/MyFoodsPage/MyFoodsPage.component';

import CookbookDetailsPage from './pages/CookbookDetailsPage/CookbookDetailsPage.component';
import ChefCookbooksListPage from './pages/ChefCookbooksListPage/ChefCookbooksListPage.component';

const HomePage = React.lazy(() => import('./pages/HomePage/HomePage.component'))

const App = () => {

  // const filters = [
  //   '/keywordRecipeName=:keywordRecipeName',
  //   '/keywordCountry=:keywordCountry',
  //   '/keywordIsVegan=:keywordIsVegan',
  //   '/keywordIsVegetarian=:keywordIsVegetarian',
  //   '/keywordIsGlutenFree=:keywordIsGlutenFree',
  //   '/keywordIsKetogenic=:keywordIsKetogenic',
  //   '/keywordIsPescatarian=:keywordIsPescatarian',
  //   '/keywordIsDairy=:keywordIsDairy',
  //   '/keywordIsEgg=:keywordIsEgg',
  //   '/keywordIsNuts=:keywordIsNuts',
  // ]
  // const filtersLength = 10
  // const filtersUrl = []
  // for (var h = 0; h < filtersLength; h++) {
  //   filtersUrl.push(filters[h]);
  // }
  // for (var i = 0; i < filtersLength - 1; i++) {
  //   for (var j = i + 1; j < filtersLength; j++) {
  //     filtersUrl.push(filters[i] + filters[j]);
  //     for (var k = j + 1; k < filtersLength; k++) {
  //       filtersUrl.push(filters[i] + filters[j] + filters[k]);
  //       for (var l = k + 1; l < filtersLength; l++) {
  //         filtersUrl.push(filters[i] + filters[j] + filters[k] + filters[l]);
  //         for (var m = l + 1; m < filtersLength; m++) {
  //           filtersUrl.push(filters[i] + filters[j] + filters[k] + filters[l] + filters[m]);
  //           for (var n = m + 1; n < filtersLength; n++) {
  //             filtersUrl.push(filters[i] + filters[j] + filters[k] + filters[l] + filters[m] + filters[n]);
  //             for (var o = n + 1; o < filtersLength; o++) {
  //               filtersUrl.push(filters[i] + filters[j] + filters[k] + filters[l] + filters[m] + filters[n] + filters[o]);
  //               for (var p = o + 1; p < filtersLength; p++) {
  //                 filtersUrl.push(filters[i] + filters[j] + filters[k] + filters[l] + filters[m] + filters[n] + filters[o] + filters[p]);
  //                 for (var q = p + 1; q < filtersLength; q++) {
  //                   filtersUrl.push(filters[i] + filters[j] + filters[k] + filters[l] + filters[m] + filters[n] + filters[o] + filters[p] + filters[q]);
  //                   for (var r = q + 1; r < filtersLength; r++) {
  //                     filtersUrl.push(filters[i] + filters[j] + filters[k] + filters[l] + filters[m] + filters[n] + filters[o] + filters[p] + filters[q] + filters[r]);
  //                   }
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  return (
    <Router>
      <Header />
      <IconBar />
      <main className="py-2" style={{maxWidth: '100vw', overflowX: 'hidden'}}>
        <div style={{paddingTop: '40px'}}>
          <Suspense fallback={<div>Loading...</div>}>
          <Route path='/login' component={LoginPage} />
          <Route path='/register' component={RegisterPage} />
          <Route path='/profile' component={ProfileEditPage} />
          <Route path='/myrecipes' component={ChefRecipesListPage} exact />
          <Route path='/myrecipes/:id/edit' component={ChefRecipeEditPage} exact />
          <Route path='/savedrecipes' component={ChefSavedRecipesListPage} exact />
          <Route path='/savedrecipes/:id' component={ChefSavedRecipesDeletePage} exact />
          <Route path='/recipes' component={AllRecipesPage} exact />
          <Route path='/recipe' component={IndividualRecipesPlaceholder} exact />
          <Route path='/recipe/:id' component={IndividualRecipePage} exact />
          <Route path='/advanced-search' component={AdvancedRecipeSearchPage} exact />
          <Route path='/recipes/search/keywordRecipeName=:keywordRecipeName/page/:pageNumber' component={AllRecipesPage} />
          <Route path='/grocerylist' component={SavedIngredientsPage} exact />
          <Route path='/chefs' component={AllChefsPage} exact />
          <Route path='/chefs/:id' component={IndividualChefPage} exact />
          <Route path='/mychefs' component={ChefMyFollowedPage} exact />
          <Route path='/cookbooks/:id' component={CookbookDetailsPage} exact />
          <Route path='/mycookbooks' component={ChefCookbooksListPage} exact />
          <Route path='/myfoods' component={MyFoodsPage} />

          {/* {(filtersUrl) && filtersUrl.map((items) =>
            <Route path={`/recipes/advanced-search-results${items}/page/:pageNumber`} component={AdvancedRecipeSearchResultsPage} exact/>
          )} */}

          {/* <Route path='/recipes/advanced-search-results/:keywordRecipeName?/:keywordCountry?/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/> */}
          <Route path='/recipes/advanced-search-results/keywordCountry=:keywordCountry/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>

          <Route path='/recipes/advanced-search-results/keywordIsAppetizer=:keywordIsAppetizer/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordIsDessert=:keywordIsDessert/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordIsMainDish=:keywordIsMainDish/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordIsSideSauce=:keywordIsSideSauce/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordIsSnack=:keywordIsSnack/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>

          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordCountry=:keywordCountry/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordChefName=:keywordChefName/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordCountry=:keywordCountry/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordChefName=:keywordChefName/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordCountry=:keywordCountry/keywordChefName=:keywordChefName/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordCountry=:keywordCountry/keywordChefName=:keywordChefName/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>

          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordCountry=:keywordCountry/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordChefName=:keywordChefName/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordCountry=:keywordCountry/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordChefName=:keywordChefName/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordCountry=:keywordCountry/keywordChefName=:keywordChefName/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordCountry=:keywordCountry/keywordChefName=:keywordChefName/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>

          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordCountry=:keywordCountry/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordChefName=:keywordChefName/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordCountry=:keywordCountry/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordChefName=:keywordChefName/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordCountry=:keywordCountry/keywordChefName=:keywordChefName/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordCountry=:keywordCountry/keywordChefName=:keywordChefName/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>

          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordCountry=:keywordCountry/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordChefName=:keywordChefName/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordCountry=:keywordCountry/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordChefName=:keywordChefName/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordCountry=:keywordCountry/keywordChefName=:keywordChefName/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordCountry=:keywordCountry/keywordChefName=:keywordChefName/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>

          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordCountry=:keywordCountry/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordChefName=:keywordChefName/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordCountry=:keywordCountry/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordChefName=:keywordChefName/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordCountry=:keywordCountry/keywordChefName=:keywordChefName/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordCountry=:keywordCountry/keywordChefName=:keywordChefName/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>

          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordCountry=:keywordCountry/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordChefName=:keywordChefName/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordCountry=:keywordCountry/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordChefName=:keywordChefName/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordCountry=:keywordCountry/keywordChefName=:keywordChefName/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordCountry=:keywordCountry/keywordChefName=:keywordChefName/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>

          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordCountry=:keywordCountry/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordChefName=:keywordChefName/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordCountry=:keywordCountry/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordChefName=:keywordChefName/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordCountry=:keywordCountry/keywordChefName=:keywordChefName/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordCountry=:keywordCountry/keywordChefName=:keywordChefName/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>

          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordCountry=:keywordCountry/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordChefName=:keywordChefName/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordCountry=:keywordCountry/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordChefName=:keywordChefName/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordCountry=:keywordCountry/keywordChefName=:keywordChefName/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordRecipeName=:keywordRecipeName/keywordCountry=:keywordCountry/keywordChefName=:keywordChefName/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>
          <Route path='/recipes/advanced-search-results/keywordCookTimeMin=:keywordCookTimeMin/keywordCookTimeMax=:keywordCookTimeMax/keywordIsVegan=:keywordIsVegan/keywordIsVegetarian=:keywordIsVegetarian/keywordIsGlutenFree=:keywordIsGlutenFree/keywordIsKetogenic=:keywordIsKetogenic/keywordIsPescatarian=:keywordIsPescatarian/keywordIsDairy=:keywordIsDairy/keywordIsEgg=:keywordIsEgg/keywordIsNuts=:keywordIsNuts/keywordIsShellfish=:keywordIsShellfish/keywordIsSoy=:keywordIsSoy/keywordIsWheat=:keywordIsWheat/keywordIsBreakfastBrunch=:keywordIsBreakfastBrunch/keywordIsMainDish=:keywordIsMainDish/keywordIsSideSauce=:keywordIsSideSauce/keywordIsDessert=:keywordIsDessert/keywordIsSnack=:keywordIsSnack/keywordIsAppetizer=:keywordIsAppetizer/keywordIsDrink=:keywordIsDrink/page/:pageNumber' component={AdvancedRecipeSearchResultsPage} exact/>

          <Route path='/' component={HomePage} exact />
          </Suspense>
        </div>
      </main>
    </Router>
  )
}

export default App;
