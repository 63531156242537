export const RECIPE_LIST_REQUEST = 'RECIPE_LIST_REQUEST'
export const RECIPE_LIST_SUCCESS = 'RECIPE_LIST_SUCCESS'
export const RECIPE_LIST_FAILURE = 'RECIPE_LIST_FAILURE'

export const RECIPE_NAMES_REQUEST = 'RECIPE_NAMES_REQUEST'
export const RECIPE_NAMES_SUCCESS = 'RECIPE_NAMES_SUCCESS'
export const RECIPE_NAMES_FAILURE = 'RECIPE_NAMES_FAILURE'

export const RECIPE_LIST_ADVANCED_SEARCH_REQUEST = 'RECIPE_LIST_ADVANCED_SEARCH_REQUEST'
export const RECIPE_LIST_ADVANCED_SEARCH_SUCCESS = 'RECIPE_LIST_ADVANCED_SEARCH_SUCCESS'
export const RECIPE_LIST_ADVANCED_SEARCH_FAILURE = 'RECIPE_LIST_ADVANCED_SEARCH_FAILURE'

export const RECIPE_MYLIST_REQUEST = 'RECIPE_MYLIST_REQUEST'
export const RECIPE_MYLIST_SUCCESS = 'RECIPE_MYLIST_SUCCESS'
export const RECIPE_MYLIST_FAILURE = 'RECIPE_MYLIST_FAILURE'

export const RECIPE_DETAILS_REQUEST = 'RECIPE_DETAILS_REQUEST'
export const RECIPE_DETAILS_SUCCESS = 'RECIPE_DETAILS_SUCCESS'
export const RECIPE_DETAILS_FAILURE = 'RECIPE_DETAILS_FAILURE'
export const RECIPE_DETAILS_RESET = 'RECIPE_DETAILS_RESET'

export const RECIPE_DELETE_REQUEST = 'RECIPE_DELETE_REQUEST'
export const RECIPE_DELETE_SUCCESS = 'RECIPE_DELETE_SUCCESS'
export const RECIPE_DELETE_FAILURE = 'RECIPE_DELETE_FAILURE'

export const RECIPE_CREATE_REQUEST = 'RECIPE_CREATE_REQUEST'
export const RECIPE_CREATE_SUCCESS = 'RECIPE_CREATE_SUCCESS'
export const RECIPE_CREATE_FAILURE = 'RECIPE_CREATE_FAILURE'
export const RECIPE_CREATE_RESET= 'RECIPE_CREATE_RESET'

export const RECIPE_UPDATE_REQUEST = 'RECIPE_UPDATE_REQUEST'
export const RECIPE_UPDATE_SUCCESS = 'RECIPE_UPDATE_SUCCESS'
export const RECIPE_UPDATE_FAILURE = 'RECIPE_UPDATE_FAILURE'
export const RECIPE_UPDATE_RESET= 'RECIPE_UPDATE_RESET'

export const RECIPE_CREATE_UPVOTE_REQUEST = 'RECIPE_CREATE_UPVOTE_REQUEST'
export const RECIPE_CREATE_UPVOTE_SUCCESS = 'RECIPE_CREATE_UPVOTE_SUCCESS'
export const RECIPE_CREATE_UPVOTE_FAILURE = 'RECIPE_CREATE_UPVOTE_FAILURE'
export const RECIPE_CREATE_UPVOTE_RESET= 'RECIPE_CREATE_UPVOTE_RESET'

export const RECIPE_CREATE_DOWNVOTE_REQUEST = 'RECIPE_CREATE_DOWNVOTE_REQUEST'
export const RECIPE_CREATE_DOWNVOTE_SUCCESS = 'RECIPE_CREATE_DOWNVOTE_SUCCESS'
export const RECIPE_CREATE_DOWNVOTE_FAILURE = 'RECIPE_CREATE_DOWNVOTE_FAILURE'
export const RECIPE_CREATE_DOWNVOTE_RESET= 'RECIPE_CREATE_DOWNVOTE_RESET'

export const RECIPE_SAVE_REQUEST = 'RECIPE_SAVE_REQUEST'
export const RECIPE_SAVE_SUCCESS = 'RECIPE_SAVE_SUCCESS'
export const RECIPE_SAVE_FAILURE = 'RECIPE_SAVE_FAILURE'
export const RECIPE_SAVE_RESET= 'RECIPE_SAVE_RESET'

export const RECIPE_UNSAVE_REQUEST = 'RECIPE_UNSAVE_REQUEST'
export const RECIPE_UNSAVE_SUCCESS = 'RECIPE_UNSAVE_SUCCESS'
export const RECIPE_UNSAVE_FAILURE = 'RECIPE_UNSAVE_FAILURE'
export const RECIPE_UNSAVE_RESET= 'RECIPE_UNSAVE_RESET'

export const RECIPE_MYSAVED_REQUEST = 'RECIPE_MYSAVED_REQUEST'
export const RECIPE_MYSAVED_SUCCESS = 'RECIPE_MYSAVED_SUCCESS'
export const RECIPE_MYSAVED_FAILURE = 'RECIPE_MYSAVED_FAILURE'

export const RECIPE_SAVE_INGREDIENTS_REQUEST = 'RECIPE_SAVE_INGREDIENTS_REQUEST'
export const RECIPE_SAVE_INGREDIENTS_SUCCESS = 'RECIPE_SAVE_INGREDIENTS_SUCCESS'
export const RECIPE_SAVE_INGREDIENTS_FAILURE = 'RECIPE_SAVE_INGREDIENTS_FAILURE'
export const RECIPE_SAVE_INGREDIENTS_RESET= 'RECIPE_SAVE_INGREDIENTS_RESET'

export const RECIPE_SAVE_TO_COOKBOOK_REQUEST = 'RECIPE_SAVE_TO_COOKBOOK_REQUEST'
export const RECIPE_SAVE_TO_COOKBOOK_SUCCESS = 'RECIPE_SAVE_TO_COOKBOOK_SUCCESS'
export const RECIPE_SAVE_TO_COOKBOOK_FAILURE = 'RECIPE_SAVE_TO_COOKBOOK_FAILURE'
export const RECIPE_SAVE_TO_COOKBOOK_RESET= 'RECIPE_SAVE_TO_COOKBOOK_RESET'

export const RECIPE_REMOVE_FROM_COOKBOOK_REQUEST = 'RECIPE_REMOVE_FROM_COOKBOOK_REQUEST'
export const RECIPE_REMOVE_FROM_COOKBOOK_SUCCESS = 'RECIPE_REMOVE_FROM_COOKBOOK_SUCCESS'
export const RECIPE_REMOVE_FROM_COOKBOOK_FAILURE = 'RECIPE_REMOVE_FROM_COOKBOOK_FAILURE'
export const RECIPE_REMOVE_FROM_COOKBOOK_RESET= 'RECIPE_REMOVE_FROM_COOKBOOK_RESET'

export const RECIPE_LIST_MOST_RECENT_LIMITED_REQUEST = 'RECIPE_LIST_MOST_RECENT_LIMITED_REQUEST'
export const RECIPE_LIST_MOST_RECENT_LIMITED_SUCCESS = 'RECIPE_LIST_MOST_RECENT_LIMITED_SUCCESS'
export const RECIPE_LIST_MOST_RECENT_LIMITED_FAILURE = 'RECIPE_LIST_MOST_RECENT_LIMITED_FAILURE'

export const RECIPE_LIST_MOST_RECENT_REQUEST = 'RECIPE_LIST_MOST_RECENT_REQUEST'
export const RECIPE_LIST_MOST_RECENT_SUCCESS = 'RECIPE_LIST_MOST_RECENT_SUCCESS'
export const RECIPE_LIST_MOST_RECENT_FAILURE = 'RECIPE_LIST_MOST_RECENT_FAILURE'

export const RECIPE_LIST_HIGHEST_RATED_LIMITED_REQUEST = 'RECIPE_LIST_HIGHEST_RATED_LIMITED_REQUEST'
export const RECIPE_LIST_HIGHEST_RATED_LIMITED_SUCCESS = 'RECIPE_LIST_HIGHEST_RATED_LIMITED_SUCCESS'
export const RECIPE_LIST_HIGHEST_RATED_LIMITED_FAILURE = 'RECIPE_LIST_HIGHEST_RATED_LIMITED_FAILURE'

export const RECIPE_LIST_HIGHEST_RATED_REQUEST = 'RECIPE_LIST_HIGHEST_RATED_REQUEST'
export const RECIPE_LIST_HIGHEST_RATED_SUCCESS = 'RECIPE_LIST_HIGHEST_RATED_SUCCESS'
export const RECIPE_LIST_HIGHEST_RATED_FAILURE = 'RECIPE_LIST_HIGHEST_RATED_FAILURE'

export const RECIPE_LIST_FIVE_INGREDIENTS_OR_FEWER_REQUEST = 'RECIPE_LIST_FIVE_INGREDIENTS_OR_FEWER_REQUEST'
export const RECIPE_LIST_FIVE_INGREDIENTS_OR_FEWER_SUCCESS = 'RECIPE_LIST_FIVE_INGREDIENTS_OR_FEWER_SUCCESS'
export const RECIPE_LIST_FIVE_INGREDIENTS_OR_FEWER_FAILURE = 'RECIPE_LIST_FIVE_INGREDIENTS_OR_FEWER_FAILURE'

export const RECIPE_LIST_TEN_INGREDIENTS_OR_FEWER_REQUEST = 'RECIPE_LIST_TEN_INGREDIENTS_OR_FEWER_REQUEST'
export const RECIPE_LIST_TEN_INGREDIENTS_OR_FEWER_SUCCESS = 'RECIPE_LIST_TEN_INGREDIENTS_OR_FEWER_SUCCESS'
export const RECIPE_LIST_TEN_INGREDIENTS_OR_FEWER_FAILURE = 'RECIPE_LIST_TEN_INGREDIENTS_OR_FEWER_FAILURE'

export const RECIPE_LIST_FIVE_STEPS_OR_FEWER_REQUEST = 'RECIPE_LIST_FIVE_STEPS_OR_FEWER_REQUEST'
export const RECIPE_LIST_FIVE_STEPS_OR_FEWER_SUCCESS = 'RECIPE_LIST_FIVE_STEPS_OR_FEWER_SUCCESS'
export const RECIPE_LIST_FIVE_STEPS_OR_FEWER_FAILURE = 'RECIPE_LIST_FIVE_STEPS_OR_FEWER_FAILURE'

export const RECIPE_LIST_TEN_STEPS_OR_FEWER_REQUEST = 'RECIPE_LIST_TEN_STEPS_OR_FEWER_REQUEST'
export const RECIPE_LIST_TEN_STEPS_OR_FEWER_SUCCESS = 'RECIPE_LIST_TEN_STEPS_OR_FEWER_SUCCESS'
export const RECIPE_LIST_TEN_STEPS_OR_FEWER_FAILURE = 'RECIPE_LIST_TEN_STEPS_OR_FEWER_FAILURE'

export const RECIPE_LIST_THIRTY_MINUTES_AND_UNDER_REQUEST = 'RECIPE_LIST_THIRTY_MINUTES_AND_UNDER_REQUEST'
export const RECIPE_LIST_THIRTY_MINUTES_AND_UNDER_SUCCESS = 'RECIPE_LIST_THIRTY_MINUTES_AND_UNDER_SUCCESS'
export const RECIPE_LIST_THIRTY_MINUTES_AND_UNDER_FAILURE = 'RECIPE_LIST_THIRTY_MINUTES_AND_UNDER_FAILURE'

export const RECIPE_LIST_SIXTY_MINUTES_AND_UNDER_REQUEST = 'RECIPE_LIST_SIXTY_MINUTES_AND_UNDER_REQUEST'
export const RECIPE_LIST_SIXTY_MINUTES_AND_UNDER_SUCCESS = 'RECIPE_LIST_SIXTY_MINUTES_AND_UNDER_SUCCESS'
export const RECIPE_LIST_SIXTY_MINUTES_AND_UNDER_FAILURE = 'RECIPE_LIST_SIXTY_MINUTES_AND_UNDER_FAILURE'